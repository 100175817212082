<template>
  <div class="col-sm-2 col-md-12 d-none d-md-block">
    <nav class="nav stepping-stones justify-content-center mt-4" role="navigation">
      <ul class="stepping-stones__path d-flex">
        <router-link
          class="stepping-stones__link"
          to="/get-ready"
          v-slot="{ isActive }"
          :aria-current="isActive ? 'page' : undefined"
        >
          <li id="get-ready-step" class="stepping-stones__stone" :class="isActive ? 'active' : ''">
            <span class="stepping-stones__text">get ready</span>
            <img class="stepping-stones__img" src="../../public/img/stones/get-ready-blank.png" alt="Get ready">
          </li>
        </router-link>
        <router-link
          class="stepping-stones__link"
          to="/gather-support"
          v-slot="{ isActive }"
          :aria-current="isActive ? 'page' : undefined"
        >
          <li id="gather-support-step" class="stepping-stones__stone" :class="isActive ? 'active' : ''">
            <span class="stepping-stones__text">gather support</span>
            <img class="stepping-stones__img" src="../../public/img/stones/gather-support-blank.png" alt="Gather support">
          </li>
        </router-link>
        <router-link
          class="stepping-stones__link"
          to="/core-team"
          v-slot="{ isActive }"
          :aria-current="isActive ? 'page' : undefined"
        >
          <li id="build-team-step" class="stepping-stones__stone" :class="isActive ? 'active' : ''">
            <span class="stepping-stones__text">build core team <br> and secure <br> backbone support</span>
            <img class="stepping-stones__img" src="../../public/img/stones/build-team-blank.png" alt="Build core team and secure backbone support">
          </li>
        </router-link>
        <router-link
          class="stepping-stones__link"
          to="/youth-action-council"
          v-slot="{ isActive }"
          :aria-current="isActive ? 'page' : undefined"
        >
          <li id="create-yac-step" class="stepping-stones__stone" :class="isActive ? 'active' : ''">
            <span class="stepping-stones__text">create <br> youth action council</span>
            <img class="stepping-stones__img" src="../../public/img/stones/create-yac-blank.png" alt="Create youth action council">
          </li>
        </router-link>
        <router-link
          class="stepping-stones__link"
          to="/common-agenda"
          v-slot="{ isActive }"
          :aria-current="isActive ? 'page' : undefined"
        >
          <li id="develop-agenda-step" class="stepping-stones__stone" :class="isActive ? 'active' : ''">
            <span class="stepping-stones__text">develop common <br> agenda and shared measurement</span>
            <img class="stepping-stones__img" src="../../public/img/stones/develop-agenda-blank.png" alt="Develop common agenda and shared measurement">
          </li>
        </router-link>
        <router-link
          class="stepping-stones__link"
          to="/action-plan"
          v-slot="{ isActive }"
          :aria-current="isActive ? 'page' : undefined"
        >
          <li id="start-action-plan-step" class="stepping-stones__stone" :class="isActive ? 'active' : ''">
            <span class="stepping-stones__text">start your action plan</span>
            <img class="stepping-stones__img" src="../../public/img/stones/start-action-plan-blank.png" alt="Start your action plan">
          </li>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'SteppingStones',
};
</script>

<style lang="scss" scoped>
.stepping-stones {
  width: $stepping-stones-width;
  &__path {
    list-style-type: none;
    max-height: $stepping-stone-path-max-height;
    min-height: $stepping-stone-path-min-height;
    position: relative;
  }
  &__stone {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: $stepping-stone-margin;
    position: relative;
    width: $stepping-stone-width;
    @include media-breakpoint-up(lg) {
      margin: $stepping-stone-margin-lg;
      .stepping-stones__text {
        font-size: $stepping-stone-font-size-lg;
      }
      .stepping-stones__img {
        max-height: $stepping-stone-img-max-height-lg;
      }
    }
    @include media-breakpoint-up(xl) {
      .stepping-stones__text {
        font-size: $stepping-stone-font-size-xl;
      }
      .stepping-stones__img {
        max-height: $stepping-stone-img-max-height-xl;
      }
    }
    &.active {
      filter: $stepping-stone-active-filter;
    }
  }
  &__img {
    max-height: $stepping-stone-img-max-height;
    min-height: $stepping-stone-img-min-height;
  }
  &__text {
    color: $stepping-stone-text-color;
    cursor: pointer;
    font-size: $stepping-stone-font-size;
    font-weight: $stepping-stone-font-weight;
    position: absolute;
    text-align: center;
  }
  &__link {
    position:relative;
  }
  #gather-support-step {
    margin-top:18px;
    transform: rotate(-18deg);
    .stepping-stones__text {
      transform: rotate(18deg);
    }
  }
  #build-team-step {
    margin-top: 7px;
  }
  #create-yac-step {
    margin-top: 20px;
    transform: rotate(-3deg);
    .stepping-stones__text {
      transform: rotate(3deg);
    }
  }
  #develop-agenda-step {
    transform: rotate(4deg);
    .stepping-stones__text {
      transform: rotate(-4deg);
    }
  }
  #start-action-plan-step {
    margin-top: 36px;
  }
}
</style>
