<template>
  <div class="row gx-1 d-flex justify-content-center align-items-start">
    <h1 class="visually-hidden">Mosaic Village</h1>
    <div class="col-md-12 col-lg-6 mb-md-5">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/NpEaa2P7qZI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-md-12 col-lg-6 mb-md-5 text-left">
      <h2 class="h2">What is Mosaic Village?</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

      <h2 class="h2">How it Works</h2>
      <p>Sed ullamcorper morbi tincidunt ornare massa eget. Commodo elit at imperdiet dui. Molestie ac feugiat sed lectus vestibulum. Donec massa sapien faucibus et molestie ac. Semper quis lectus nulla at volutpat diam ut venenatis. At urna condimentum mattis pellentesque id nibh tortor id aliquet. Arcu risus quis varius quam quisque id. Sed turpis tincidunt id aliquet risus. Id semper risus in hendrerit gravida rutrum quisque. Purus sit amet volutpat consequat mauris nunc congue nisi vitae. Molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Sit amet venenatis urna cursus. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi. Libero id faucibus nisl tincidunt eget nullam non. Vitae justo eget magna fermentum iaculis eu non diam phasellus. Risus quis varius quam quisque id diam vel quam elementum.</p>
      <p>
        <router-link tag="button" class="btn btn-info btn-sm" to="/get-ready">Get Ready</router-link>
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
};
</script>

<style lang="scss" scoped>
  .btn-info {
    &,
    &:link,
    &:visited {
      color:$light;
      transition:$transition-default;
    }
    &:active,
    &:hover {
      background:darken($info,5%);
      color: $light;
      outline:none;
    }
    &:focus {
      background:darken($info,5%);
      color: $light;
      outline:none;
    }
  }
</style>
