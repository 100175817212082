const moduleState = {

};

const actions = {

};

const mutations = {

};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
