import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/get-ready',
    name: 'GetReady',
    component: () => import(/* webpackChunkName: "getready" */ '../views/GetReady.vue'),
  },
  {
    path: '/gather-support',
    name: 'GatherSupport',
    component: () => import(/* webpackChunkName: "gathersupport" */ '../views/GatherSupport.vue'),
  },
  {
    path: '/core-team',
    name: 'CoreTeam',
    component: () => import(/* webpackChunkName: "coreteam" */ '../views/CoreTeam.vue'),
  },
  {
    path: '/youth-action-council',
    name: 'YouthActionCouncil',
    component: () => import(/* webpackChunkName: "youthactioncouncil" */ '../views/YouthActionCouncil.vue'),
  },
  {
    path: '/common-agenda',
    name: 'CommonAgenda',
    component: () => import(/* webpackChunkName: "commonagenda" */ '../views/CommonAgenda.vue'),
  },
  {
    path: '/action-plan',
    name: 'ActionPlan',
    component: () => import(/* webpackChunkName: "actionplan" */ '../views/ActionPlan.vue'),
  },
  {
    path: '/brand',
    name: 'BrandGuidelineTest',
    component: () => import(/* webpackChunkName: "brand" */ '../views/BrandGuidelineTest.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
