<template>
  <div class="container-fluid d-flex flex-column justify-content-center h-100 px-0">
    <MainNav></MainNav>
    <main class="flex-shrink-0 py-5">
      <router-view/>
    </main>
    <footer class="footer mt-auto">
      <div class="container">
        <SteppingStones></SteppingStones>
      </div>
    </footer>
  </div>
</template>
<script>
import MainNav from '@/components/MainNav.vue';
import SteppingStones from '@/components/SteppingStones.vue';

export default {
  name: 'App',
  components: {
    MainNav,
    SteppingStones,
  },
};

</script>

<style lang="scss">
.app {
  color: $body-color;
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::selection {
  background: $text-highlight-bg-color;
  color: $text-highlight-text-color;
}
</style>
