const moduleState = {
  testCounter: 5,
};

const actions = {
  increaseCount({ commit }) {
    commit('increaseCounter');
  },
};

const mutations = {
  increaseCounter(state) {
    state.testCounter = state.testCounter + 1;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
