import { createStore } from 'vuex';
import partners from './partners.module';
import readinessAssessment from './readinessAssessment.module';
import resources from './resources.module';
import VuexPersistence from 'vuex-persist';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    readinessAssessment,
    partners,
    resources,
  },
  plugins: [new VuexPersistence().plugin],
});
